@font-face {
font-family: '__jp_934a56';
src: url(/_next/static/media/8617a8e9ad86fd9a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
}@font-face {font-family: '__jp_Fallback_934a56';src: local("Arial");ascent-override: 98.34%;descent-override: 24.44%;line-gap-override: 0.00%;size-adjust: 117.04%
}.__className_934a56 {font-family: '__jp_934a56', '__jp_Fallback_934a56';font-weight: 900
}.__variable_934a56 {--font-jp: '__jp_934a56', '__jp_Fallback_934a56'
}

